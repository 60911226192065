import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import '@/assets/css/utilities.css';
import '@/assets/css/fonts.css';
import '@/assets/css/styles.css';
import '@/assets/css/transitions.css';
import '@/assets/css/global.scss';
import '@/axios/index';

// import and add fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
    faHeart as fasHeart, 
    faComment, 
    faPlus, 
    faTimes, 
    faBars, 
    faFile, 
    faCaretDown, 
    faCaretRight,
    faEllipsisV,
    faChevronLeft,
    faChevronRight,
    faColumns,
    faUser,
    faKey,
    faToolbox,
    faUsers,
    faNewspaper,
    faPen,
    faAlignCenter,
    faWrench,
    faThumbsUp,
    faQuestion,
    faLightbulb,
    faBook,
    faUpload,
    faArchive,
    faCogs
 } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fasHeart);
library.add(faComment);
library.add(faPlus);
library.add(faTimes);
library.add(faBars);
library.add(faFile);
library.add(faCaretDown);
library.add(faCaretRight);
library.add(faEllipsisV);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faColumns);
library.add(faUser);
library.add(faKey);
library.add(faToolbox);
library.add(faUsers);
library.add(faNewspaper);
library.add(faPen);
library.add(faAlignCenter);
library.add(faWrench);
library.add(faThumbsUp);
library.add(faQuestion);
library.add(faLightbulb);
library.add(faBook);
library.add(faUpload);
library.add(faArchive);
library.add(faCogs);
library.add(faHeart);

const app = createApp(App);
app.use(Quasar, quasarUserOptions);
app.use(router);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');

app.provide('$dateOptions', {
    year: 'numeric', 
    month: 'long', 
    day: 'numeric'
});

app.config.globalProperties.window = window;
