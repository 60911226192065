<template>
    <!--
        menu
        1 ... main menu
        2 ... blog menu

        layout
        1 ... small sidebar
        2 ... no sidebar
        3 ... big sidebar
        3 ... news
    -->
    <div v-if="website_configuration">
        <div v-if="menu === 1" class="mobile-menu">
            <div class="container flex">
                <div class="primary-container">
                    <div class="header-logo">
                        <router-link @click="toggleMobileMenu" to="/">
                            <img :src="colorSchemeTwoActivated ? `${publicPath}images/TEEM-Logo-weiss.svg` : `${publicPath}images/TEEM-Logo-blau.svg`" alt="">
                        </router-link>
                    </div>
                </div>
                <div class="secondary-container">
                    <button class="mobile-nav-toggle-button" @click.stop.prevent="toggleMobileMenu">
                        <font-awesome-icon :icon="['fas', 'times']" class="icon" />
                    </button>
                </div>
            </div>
            
            <div class="container flex">
                <nav class="mobile-nav">
                    <div :class="[$route.path.includes('/learning-platform') ? 'active' : '', 'main-menu-item', 'expandable-menu-item']" tabindex="0">
                        <div class="expandable-text"><span>Learning Platform</span></div>
                        <div class="expandable-menu">
                            <router-link 
                                v-if="website_configuration.show_theories || admin || theories"
                                :class="[$route.path.includes('/theory') ? 'active' : '', 'main-submenu-item']" 
                                to="/learning-platform/theory/25"
                                @click="toggleMobileMenu">The TEEM Project</router-link>
                            <router-link 
                                :class="[$route.path.includes('/modules') ? 'active' : '', 'main-submenu-item']" 
                                to="/learning-platform/modules"
                                @click="toggleMobileMenu">Modules</router-link>
                            <router-link 
                                v-if="website_configuration.show_database || admin || database"
                                :class="[$route.path.includes('/database') ? 'active' : '', 'main-submenu-item']" 
                                to="/learning-platform/database"
                                @click="toggleMobileMenu">Database</router-link>
                            <router-link 
                                v-if="website_configuration.show_instructions || admin || instructions"
                                :class="[$route.path.includes('/instructions') ? 'active' : '', 'main-submenu-item']" 
                                to="/learning-platform/instructions"
                                @click="toggleMobileMenu">Instructions</router-link>
                            <router-link 
                                v-if="website_configuration.show_good_practices || admin || good_practices"
                                :class="[$route.path.includes('/good-practices') ? 'active' : '', 'main-submenu-item']" 
                                to="/learning-platform/good-practices"
                                @click="toggleMobileMenu">Good Practice</router-link>
                            <router-link 
                                v-if="website_configuration.show_qna || admin || qna"
                                :class="[$route.path.includes('/qna') ? 'active' : '', 'main-submenu-item']" 
                                to="/learning-platform/qna"
                                @click="toggleMobileMenu">Q&A</router-link>
                            <router-link 
                                v-if="website_configuration.show_glossary || admin || glossary"
                                :class="[$route.path.includes('/glossary') ? 'active' : '', 'main-submenu-item']" 
                                to="/learning-platform/glossary"
                                @click="toggleMobileMenu">Glossary</router-link>
                            <a
                                class="main-submenu-item"
                                href="https://matrix.geschichtsdidaktik.eu"
                                target="_blank"
                            >Planungsmatrix</a>
                        </div>
                    </div>

                    <router-link 
                    :class="[$route.path.includes('/news') ? 'active' : '', 'main-menu-item']" 
                    to="/news"
                    @click="toggleMobileMenu"><span>News + Events</span></router-link>

                    <router-link 
                        :class="[$route.path.includes('/blog') ? 'active' : '', 'main-menu-item']" 
                        to="/blog"
                        @click="toggleMobileMenu"><span>Blog</span></router-link>

                    <div :class="[$route.path.includes('/about') ? 'active' : '', 'main-menu-item', 'expandable-menu-item']" tabindex="0">
                        <div class="expandable-text"><span>About</span></div>
                        <div class="expandable-menu">
                            <router-link 
                                :class="[$route.path.includes('/about-teem') ? 'active' : '', 'main-submenu-item']" 
                                to="/about/about-teem"
                                @click="toggleMobileMenu">About TEEM</router-link>
                            <router-link 
                                :class="[$route.path.includes('/project-team') ? 'active' : '', 'main-submenu-item']" 
                                to="/about/project-team"
                                @click="toggleMobileMenu">Project Team</router-link>
                        </div>
                    </div>

                    <router-link 
                        :class="[$route.path.includes('/policy-paper') ? 'active' : '', 'main-menu-item']" 
                        to="/policy-paper"
                        @click="toggleMobileMenu"><span>Policy Paper</span></router-link>

                </nav>
                <div class="mobile-user-menu">
                    <TheSecondaryMenu @toggleMobileMenu="toggleMobileMenu" @handleLogout="handleLogout" />
                </div>
            </div>

            <div class="container flex footer">
                <nav>
                    <router-link 
                        :class="[$route.path.includes('/contact') ? 'active' : '', 'footer-menu-item']" 
                        to="/contact"
                        @click="toggleMobileMenu">Contact</router-link>
                    <router-link 
                        :class="[$route.path.includes('/legal-notice') ? 'active' : '', 'footer-menu-item']" 
                        to="/legal-notice"
                        @click="toggleMobileMenu">Legal Notice</router-link>
                    <router-link 
                        :class="[$route.path.includes('/privacy-policy') ? 'active' : '', 'footer-menu-item']" 
                        to="/privacy-policy"
                        @click="toggleMobileMenu">Privacy Policy</router-link>
                </nav>
                <div class="footer-logo">
                    <img :src="`${publicPath}images/eu-erasmus-logo.png`" alt="">
                </div>
            </div>
        </div>
        <div v-if="menu === 2" class="mobile-menu">
            <div class="container flex">
                <div class="primary-container">
                    <div class="header-logo">
                        <router-link to="/">
                            <img :src="colorSchemeTwoActivated ? `${publicPath}images/TEEM-Logo-weiss.svg` : `${publicPath}images/TEEM-Logo-blau.svg`" alt="">
                        </router-link>
                    </div>
                </div>
                <div class="secondary-container">
                    <button class="mobile-nav-toggle-button" @click.stop.prevent="toggleMobileMenu">
                        <font-awesome-icon :icon="['fas', 'times']" class="icon" />
                    </button>
                </div>
            </div>
            
            <div class="container flex">
                <nav class="mobile-nav">
                    <router-link 
                        :class="[$route.path.includes('/blog') ? 'active' : '', 'main-menu-item']" 
                        to="/blog"
                        @click="toggleMobileMenu"><span>Blog</span></router-link>

                    <router-link 
                        :class="[$route.path.includes('/blog/welcome') ? 'active' : '', 'main-menu-item']" 
                        to="/blog/welcome"
                        @click="toggleMobileMenu"><span>Welcome to the Blog</span></router-link>

                    <br>
                    <br>

                    <router-link 
                        :class="[$route.path.includes('/blog') ? 'active' : '', 'main-menu-item']" 
                        to="/"
                        @click="toggleMobileMenu"><span>TEEM Website</span></router-link>

                </nav>
                <div class="mobile-user-menu">
                    <TheSecondaryMenu @toggleMobileMenu="toggleMobileMenu" @handleLogout="handleLogout" />
                </div>
            </div>

            <div class="container flex footer">
                <nav>
                    <router-link 
                        :class="[$route.path.includes('/contact') ? 'active' : '', 'footer-menu-item']" 
                        to="/contact"
                        @click="toggleMobileMenu">Contact</router-link>
                    <router-link 
                        :class="[$route.path.includes('/legal-notice') ? 'active' : '', 'footer-menu-item']" 
                        to="/legal-notice"
                        @click="toggleMobileMenu">Legal Notice</router-link>
                </nav>
                <div class="footer-logo">
                    <img :src="`${publicPath}images/eu-erasmus-logo.png`" alt="">
                </div>
            </div>
        </div>



        <header v-if="menu === 1 && (layout === 1 || layout === 3)" class="default-sidebar container flex">
            <div :class="[layout === 3 ? 'wide' : '','primary-container']">
                <div class="flex">
                    <div class="header-logo">
                        <router-link to="/">
                            <img :src="colorSchemeTwoActivated ? `${publicPath}images/TEEM-Logo-weiss.svg` : `${publicPath}images/TEEM-Logo-blau.svg`" alt="">
                        </router-link>
                    </div>
                    <nav class="primary-nav">
                        <div :class="[$route.path.includes('/learning-platform') ? 'active' : '', 'main-menu-item', 'expandable-menu-item']" tabindex="0">
                            <div class="expandable-text">Learning Platform</div>
                            <div class="expandable-menu">
                                <router-link 
                                    v-if="website_configuration.show_theories || admin || theories"
                                    :class="[$route.path.includes('/theory') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/theory/25">The TEEM Project</router-link>
                                <router-link 
                                    :class="[$route.path.includes('/modules') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/modules">Modules</router-link>
                                <router-link 
                                    v-if="website_configuration.show_database || admin || database"
                                    :class="[$route.path.includes('/database') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/database">Database</router-link>
                                <router-link 
                                    v-if="website_configuration.show_instructions || admin || instructions"
                                    :class="[$route.path.includes('/instructions') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/instructions">Instructions</router-link>
                                <router-link 
                                    v-if="website_configuration.show_good_practices || admin || good_practices"
                                    :class="[$route.path.includes('/good-practices') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/good-practices">Good Practice</router-link>
                                <router-link 
                                    v-if="website_configuration.show_qna || admin || qna"
                                    :class="[$route.path.includes('/qna') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/qna">Q&A</router-link>
                                <router-link 
                                    v-if="website_configuration.show_glossary || admin || glossary"
                                    :class="[$route.path.includes('/glossary') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/glossary">Glossary</router-link>
                                <a
                                    class="main-submenu-item"
                                    href="https://matrix.geschichtsdidaktik.eu"
                                    target="_blank"
                                >Planungsmatrix</a>
                            </div>
                        </div>

                        <router-link 
                            :class="[$route.path.includes('/policy-paper') ? 'active' : '', 'main-menu-item']" 
                            to="/policy-paper">Policy Paper</router-link>

                        <div :class="[$route.path.includes('/about') ? 'active' : '', 'main-menu-item', 'expandable-menu-item']" tabindex="0">
                            <div class="expandable-text">About</div>
                            <div class="expandable-menu">
                                <router-link 
                                    :class="[$route.path.includes('/about-teem') ? 'active' : '', 'main-submenu-item']" 
                                    to="/about/about-teem">About TEEM</router-link>
                                <router-link 
                                    :class="[$route.path.includes('/project-team') ? 'active' : '', 'main-submenu-item']" 
                                    to="/about/project-team">Project Team</router-link>
                            </div>
                        </div>

                        <router-link 
                            :class="[$route.path.includes('/blog') ? 'active' : '', 'main-menu-item']" 
                            to="/blog">Blog
                        </router-link>
                    </nav>
                </div>
            </div>
            <div :class="[layout === 3 ? 'wide' : '','secondary-container']">
                <div class="user-menu">
                    <TheSecondaryMenu @handleLogout="handleLogout" />
                </div>
                <nav class="secondary-nav">
                    <router-link 
                        :class="[$route.path.includes('/news') ? 'active' : '', 'main-menu-item']" 
                        to="/news">News + Events</router-link>
                </nav>
                <button class="mobile-nav-toggle-button" @click.stop.prevent="toggleMobileMenu">
                    <font-awesome-icon :icon="['fas', 'bars']" class="icon" />
                </button>
            </div>
        </header>
        <header v-if="menu === 1 && layout === 2" class="container flex">
            <div style="width: 100%" class="primary-container">
                <div style="position: absolute; right: 0; padding-right: 3rem;" class="user-menu">
                    <TheSecondaryMenu @handleLogout="handleLogout" />
                </div>
                <div class="flex">
                    <div class="header-logo">
                        <router-link to="/">
                            <img :src="colorSchemeTwoActivated ? `${publicPath}images/TEEM-Logo-weiss.svg` : `${publicPath}images/TEEM-Logo-blau.svg`" alt="">
                        </router-link>
                    </div>
                    <nav class="primary-nav">
                        <div :class="[$route.path.includes('/learning-platform') ? 'active' : '', 'main-menu-item', 'expandable-menu-item']" tabindex="0">
                            <div class="expandable-text">Learning Platform</div>
                            <div class="expandable-menu">
                                <router-link 
                                    v-if="website_configuration.show_theories || admin || theories"
                                    :class="[$route.path.includes('/theory') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/theory/25">The TEEM Project</router-link>
                                <router-link 
                                    :class="[$route.path.includes('/modules') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/modules">Modules</router-link>
                                <router-link 
                                    v-if="website_configuration.show_database || admin || database"
                                    :class="[$route.path.includes('/database') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/database">Database</router-link>
                                <router-link 
                                    v-if="website_configuration.show_instructions || admin || instructions"
                                    :class="[$route.path.includes('/instructions') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/instructions">Instructions</router-link>
                                <router-link 
                                    v-if="website_configuration.show_good_practices || admin || good_practices"
                                    :class="[$route.path.includes('/good-practices') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/good-practices">Good Practice</router-link>
                                <router-link 
                                    v-if="website_configuration.show_qna || admin || qna"
                                    :class="[$route.path.includes('/qna') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/qna">Q&A</router-link>
                                <router-link 
                                    v-if="website_configuration.show_glossary || admin || glossary"
                                    :class="[$route.path.includes('/glossary') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/glossary">Glossary</router-link>
                                <a
                                    class="main-submenu-item"
                                    href="https://matrix.geschichtsdidaktik.eu"
                                    target="_blank"
                                >Planungsmatrix</a>
                            </div>
                        </div>

                        <router-link 
                            :class="[$route.path.includes('/policy-paper') ? 'active' : '', 'main-menu-item']" 
                            to="/policy-paper">Policy Paper</router-link>

                        <div :class="[$route.path.includes('/about') ? 'active' : '', 'main-menu-item', 'expandable-menu-item']" tabindex="0">
                            <div class="expandable-text">About</div>
                            <div class="expandable-menu">
                                <router-link 
                                    :class="[$route.path.includes('/about-teem') ? 'active' : '', 'main-submenu-item']" 
                                    to="/about/about-teem">About TEEM</router-link>
                                <router-link 
                                    :class="[$route.path.includes('/project-team') ? 'active' : '', 'main-submenu-item']" 
                                    to="/about/project-team">Project Team</router-link>
                            </div>
                        </div>

                        <router-link 
                            :class="[$route.path.includes('/blog') ? 'active' : '', 'main-menu-item']" 
                            to="/blog">Blog</router-link>
                        <!-- Change back to router link -->
                        <router-link 
                            :class="[$route.path.includes('/news') ? 'active' : '', 'main-menu-item']" 
                            to="/news">News + Events</router-link>
                    </nav>
                    
                </div>
            </div>
            <div class="secondary-container mobile">
                <button class="mobile-nav-toggle-button" @click.stop.prevent="toggleMobileMenu">
                    <font-awesome-icon :icon="['fas', 'bars']" class="icon" />
                </button>
            </div>
        </header>
        <header v-if="menu === 2 && (layout === 1 || layout === 3)" class="default-sidebar container flex">
            <div :class="[layout === 3 ? 'wide' : '','primary-container']">
                <div class="flex">
                    <div class="header-logo">
                        <router-link to="/">
                            <img :src="colorSchemeTwoActivated ? `${publicPath}images/TEEM-Logo-weiss.svg` : `${publicPath}images/TEEM-Logo-blau.svg`" alt="">
                        </router-link>
                    </div>
                    <nav class="primary-nav">
                        <router-link 
                            :class="[$route.path.includes('/article') ? 'active' : '', 'main-menu-item']" 
                            to="/blog/article/1">Blog</router-link>

                        <router-link 
                            :class="[$route.path.includes('/blog') && !$route.path.includes('/article') ? 'active' : '', 'main-menu-item']" 
                            to="/blog">Welcome to the Blog</router-link>
                    </nav>
                </div>
            </div>
            <div :class="[layout === 3 ? 'wide' : '','secondary-container']">
                <div style="position: absolute; right: 0; padding-right: 5rem;" class="user-menu">
                    <TheSecondaryMenu @handleLogout="handleLogout" />
                </div>
                <button class="mobile-nav-toggle-button" @click.stop.prevent="toggleMobileMenu">
                    <font-awesome-icon :icon="['fas', 'bars']" class="icon" />
                </button>
            </div>
        </header>
        <header v-if="menu === 1 && (layout === 4)" class="news-sidebar container flex">
            <div :class="[layout === 3 ? 'wide' : '','primary-container']">
                <div class="flex">
                    <div class="header-logo">
                        <router-link to="/">
                            <img :src="colorSchemeTwoActivated ? `${publicPath}images/TEEM-Logo-weiss.svg` : `${publicPath}images/TEEM-Logo-blau.svg`" alt="">
                        </router-link>
                    </div>
                    <nav class="primary-nav">
                        <div :class="[$route.path.includes('/learning-platform') ? 'active' : '', 'main-menu-item', 'expandable-menu-item']" tabindex="0">
                            <div class="expandable-text">Learning Platform</div>
                            <div class="expandable-menu">
                                <router-link 
                                    v-if="website_configuration.show_theories || admin || theories"
                                    :class="[$route.path.includes('/theory') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/theory/25">The TEEM Project</router-link>
                                <router-link 
                                    :class="[$route.path.includes('/modules') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/modules">Modules</router-link>
                                <router-link 
                                    v-if="website_configuration.show_database || admin || database"
                                    :class="[$route.path.includes('/database') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/database">Database</router-link>
                                <router-link 
                                    v-if="website_configuration.show_instructions || admin || instructions"
                                    :class="[$route.path.includes('/instructions') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/instructions">Instructions</router-link>
                                <router-link 
                                    v-if="website_configuration.show_good_practices || admin || good_practices"
                                    :class="[$route.path.includes('/good-practices') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/good-practices">Good Practice</router-link>
                                <router-link 
                                    v-if="website_configuration.show_qna || admin || qna"
                                    :class="[$route.path.includes('/qna') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/qna">Q&A</router-link>
                                <router-link 
                                    v-if="website_configuration.show_glossary || admin || glossary"
                                    :class="[$route.path.includes('/glossary') ? 'active' : '', 'main-submenu-item']" 
                                    to="/learning-platform/glossary">Glossary</router-link>
                                <a
                                    class="main-submenu-item"
                                    href="https://matrix.geschichtsdidaktik.eu"
                                    target="_blank"
                                >Planungsmatrix</a>
                            </div>
                        </div>

                        <router-link 
                            :class="[$route.path.includes('/policy-paper') ? 'active' : '', 'main-menu-item']" 
                            to="/policy-paper">Policy Paper</router-link>

                        <div :class="[$route.path.includes('/about') ? 'active' : '', 'main-menu-item', 'expandable-menu-item']" tabindex="0">
                            <div class="expandable-text">About</div>
                            <div class="expandable-menu">
                                <router-link 
                                    :class="[$route.path.includes('/about-teem') ? 'active' : '', 'main-submenu-item']" 
                                    to="/about/about-teem">About TEEM</router-link>
                                <router-link 
                                    :class="[$route.path.includes('/project-team') ? 'active' : '', 'main-submenu-item']" 
                                    to="/about/project-team">Project Team</router-link>
                            </div>
                        </div>

                        <router-link 
                            :class="[$route.path.includes('/blog') ? 'active' : '', 'main-menu-item']" 
                            to="/blog">Blog
                        </router-link>
                    </nav>

                </div>
                
            </div>
            <div :class="[layout === 3 ? 'wide' : '','secondary-container']">
                <div class="user-menu">
                    <TheSecondaryMenu @handleLogout="handleLogout" />
                </div>
                
                <nav class="secondary-nav">
                    <!-- change back to router link -->
                    <router-link 
                        :class="[$route.path.includes('/news') ? 'active' : '', 'main-menu-item']" 
                        to="/news">News + Events</router-link>
                </nav>

                <button class="mobile-nav-toggle-button" @click.stop.prevent="toggleMobileMenu">
                    <font-awesome-icon :icon="['fas', 'bars']" class="icon" />
                </button>
            </div>
        </header>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TheSecondaryMenu from './TheSecondaryMenu.vue';

export default {
    name: 'TheHeader',
    components: {
        TheSecondaryMenu,
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            colorSchemeTwoActivated: false,
            showMobileMenu: false,
        }
    },
    computed: {
        ...mapGetters(['user', 'layout', 'menu', 'website_configuration']),
        admin() {
            if (this.user) {
                if (this.user.user_role_numbers.includes(18)) {
                    return true;
                }
            }
            return false;
        },
        glossary() {
            if (this.user) {
                if (this.user.user_role_numbers.includes(18) ||
                    this.user.user_role_numbers.includes(2) ||
                    this.user.user_role_numbers.includes(3) ||
                    this.user.user_role_numbers.includes(4) ||
                    this.user.user_role_numbers.includes(5) ||
                    this.user.user_role_numbers.includes(20)) {
                    return true;
                }
            }
            return false;
        },
        database() {
            if (this.user) {
                if (this.user.user_role_numbers.includes(18) ||
                    this.user.user_role_numbers.includes(2) ||
                    this.user.user_role_numbers.includes(3) ||
                    this.user.user_role_numbers.includes(4) ||
                    this.user.user_role_numbers.includes(5) ||
                    this.user.user_role_numbers.includes(20)) {
                    return true;
                }
            }
            return false;
        },
        instructions() {
            if (this.user) {
                if (this.user.user_role_numbers.includes(18) ||
                    this.user.user_role_numbers.includes(24) ||
                    this.user.user_role_numbers.includes(25) ||
                    this.user.user_role_numbers.includes(26) ||
                    this.user.user_role_numbers.includes(27)) {
                    return true;
                }
            }
            return false;
        },
        qna() {
            if (this.user) {
                if (this.user.user_role_numbers.includes(18) ||
                    this.user.user_role_numbers.includes(32) ||
                    this.user.user_role_numbers.includes(33) ||
                    this.user.user_role_numbers.includes(34) || 
                    this.user.user_role_numbers.includes(35)) {
                    return true;
                }
            }
            return false;
        },
        good_practices() {
            if (this.user) {
                if (this.user.user_role_numbers.includes(18) ||
                    this.user.user_role_numbers.includes(28) ||
                    this.user.user_role_numbers.includes(29) ||
                    this.user.user_role_numbers.includes(30) ||
                    this.user.user_role_numbers.includes(31)) {
                    return true;
                }
            }
            return false;
        },
        theories() {
            if (this.user) {
                if (this.user.user_role_numbers.includes(36) ||
                    this.user.user_role_numbers.includes(37) ||
                    this.user.user_role_numbers.includes(38) ||
                    this.user.user_role_numbers.includes(39) ||
                    this.user.user_role_numbers.includes(31)) {
                    return true;
                }
            }
            return false;
        },
    },
    updated() {
        this.colorSchemeTwoActivated = document.querySelector('#app').classList.contains('color-scheme-two');
    },
    methods: {
        toggleMobileMenu() {
            if (this.showMobileMenu) {
                document.querySelector('.mobile-menu').classList.remove('show-me');
                document.querySelector('body').classList.remove('overflow-hidden');
                document.querySelector('html').classList.remove('overflow-hidden');
            } else {
                document.querySelector('.mobile-menu').classList.add('show-me');
                document.querySelector('body').classList.add('overflow-hidden');
                document.querySelector('html').classList.add('overflow-hidden');
            }
            this.showMobileMenu = !this.showMobileMenu;
        },
        handleLogout() {
            this.$emit('handleLogout')
        }
    },
    emits: ['handleLogout']
}
</script>

<style lang="scss" scoped>
header {
    padding-top: 4rem;
    z-index: 10;
}

.container {
    justify-content: space-between;
}

.disable {
    pointer-events: none;
}

.expandable-menu {
    color: var(--clr-primary);
    display: none;
    left: -14px;
    min-width: 280px;
    padding-top: 10px;
    position: absolute;
    top: 1em;
    width: calc(100% + 28px);
    z-index: 5;

    & > a {
        background-color: var(--clr-bg-primary);
        display: block;
        border-bottom-width: 2px;
        border-color: var(--clr-primary-active);
        border-left-width: 2px;
        border-right-width: 2px;
        border-style: solid;
        padding-left: 14px;
        padding-top: 5px;
    }

    & > a:hover {
        color: var(--clr-primary-active);
    }

    & > a:first-of-type {
        border-top-width: 2px;
    }
}

.expandable-menu-item {
    display: inline;
    position: relative;

    .expandable-text {
        cursor: default;
        display: inline;
        z-index: 10;
    }

    &:hover .expandable-menu {
        display: block;
    }
}

.flex {
    flex-wrap: nowrap;
}

.header-logo {
    display: block;
    align-self: flex-end;

    img {
        width: clamp(8rem, -5rem + 19vw, 18.75rem);
    }
}

.main-menu-item {
    border-bottom: 0.07em solid;
    color: var(--clr-primary);
    font-size: clamp(1rem, 0.6rem + 1vw, 1.875rem);
    font-weight: 700;
    letter-spacing: 0.3px;
    line-height: 1.1;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        color: var(--clr-primary-active);
    }

    &.active {
        color: var(--clr-primary-active);
        cursor: auto;
    }
}

.mobile-menu {
    display: none;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--clr-mobile-menu-bg);

    z-index: 100;

    padding-top: 2rem;

    img {
        opacity: 0.6;
    }

    .expandable-menu {
        margin-top: 0;
        margin-left: 5rem;
    }

    .expandable-menu-item:hover {
        color: var(--clr-mobile-menu);
        background-color: var(--clr-bg-primary);
        display: block;
        border-width: 2px;
        border-color: var(--clr-primary-active);
        border-style: solid;
        
        .expandable-text span {
            text-decoration: none;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        padding-bottom: 2rem;
    }

    .footer-logo img {
        width: 250px;
    }

    .footer-menu-item {
        color: var(--clr-mobile-menu);
        font-size: clamp(1rem, 0.8rem + 0.5vw, 1.5625rem);
        font-weight: 700;
        letter-spacing: 0.25px;
        text-transform: uppercase;
        text-decoration: none;
        padding-left: 3rem;
    }

    .main-menu-item {
        cursor: pointer;
        display: block;
        border-bottom: none;
        color: var(--clr-mobile-menu);
        margin-bottom: 1em;
        padding-top: 5px;
        padding-inline-start: 14px;
        padding-inline-end: 14px;

        span {
            text-decoration: underline;
            text-decoration-thickness: 1px;
        }
    }

    .mobile-nav {
        margin-top: 6rem;
        margin-inline-start: 3rem;
        margin-inline-end: 3rem;
    }

    .main-submenu-item {
        color: var(--clr-mobile-menu);
    }

    .mobile-user-menu {
        margin-top: 6rem;
        margin-inline-start: 3rem;
        margin-inline-end: 3rem;
        text-align: right;
    }
}

.mobile-nav-toggle-button {
    display: none;
}

.navbar {
    position: relative;
    width: 100%;
}

.primary-container {
    position: relative;

    & > .flex {
        justify-content: flex-start;
        width: 100%;
        position: absolute;
        bottom: 0;
    }

    &.wide > flex {
        padding-bottom: 2rem;
    }
}

.primary-nav {
    display: inline-block;
    align-self: flex-end;
    padding-bottom: 1.5rem;

    & > a,
    & > div,
    & > span {
        margin-left: clamp(1rem, 1rem + 1vw, 2.625rem);
    }

    & > *:first-child {
        margin-left: 3.5rem;
    }
}

.secondary-container {
    align-self: flex-end;
    padding: 0;
}

.secondary-nav {
    display: inline-block;
    text-align: left;
    align-self: flex-end;
    padding-bottom: 1.5rem;
}

.user-menu {
    z-index: 10;
    text-align: right;
    padding-bottom: 2rem;
}

@media (min-width: 75rem) {
    .default-sidebar .secondary-container {
        background-color: var(--clr-bg-secondary);
        margin-right: -74px;
        margin-top: -5rem;
        padding-top: 5rem;
        padding-left: 1.75rem;
        padding-right: calc(1.75rem + 74px);
        position: relative;
        width: calc(25% + 74px);
        -webkit-clip-path: polygon(100px 0, 100% 0, 100% 26px,calc(100% - 74px) 7rem, calc(100% - 74px) 100%, 0 100%, 0 7rem);
        clip-path: polygon(100px 0, 100% 0, 100% 26px, calc(100% - 74px) 7rem, calc(100% - 74px) 100%, 0 100%, 0 7rem);
        overflow: visible;
        align-self: flex-end;

        &.wide {
            width: calc(49% + 74px);
            min-height: 11rem;
            -webkit-clip-path: polygon(74px 0, calc(100% + 74px) 0, 100% 65%, 0% 100%);
            clip-path: polygon(250px 0, 100% 0, 100% 100px, calc(100% - 74px) 10rem, calc(100% - 74px) 100%, 0 100%, 0 10rem);
            overflow: visible;
            align-self: center;
        }
    }

    .mobile {
        display: none;
    }

    .news-sidebar .secondary-container {
        margin-right: -74px;
        margin-top: -4rem;
        padding-top: 5rem;
        padding-left: 7.75rem;
        padding-right: calc(1.75rem + 74px);
        position: relative;
        margin-left: -5rem;
        width: calc(25% + 74px + 6rem);
        overflow: visible;
        align-self: flex-end;
    }

    .primary-container {
        min-height: 7.9rem;
    }
}

@media (max-width: 75rem) {
    header {
        margin-top: -1rem;
        padding-top: 2rem;
        z-index: 10;
        margin-bottom: 5rem;
    }

    .header-logo img {
        width: clamp(4rem, 30vw, 16rem);
    }

    .mobile-nav-toggle-button {
        display: block;
        overflow: hidden;
    }

    .primary-container > .flex {
        position: inherit;
    }

    .primary-nav {
        display: none;
    }

    .secondary-container,
    .secondary-container.wide {
        background-color: var(--clr-mobile-menu-bg);
        z-index: 10;
        margin-top: -2rem;
        padding-top: 2rem;
        margin-right: -2rem;
        padding-right: 2rem;
        padding-left: 0;
        align-self: flex-start;
        flex-shrink: 1;
        width: auto;
        -webkit-clip-path: polygon(2rem 0, 100% 0, calc(100% - 2rem) 2rem, calc(100% - 2rem) 100%, 0 100%, 0 2rem);
        clip-path: polygon(clamp(0.8rem, 8vw, 2rem) 0, 100% 0, calc(100% - clamp(0.8rem, 8vw, 2rem)) clamp(0.8rem, 8vw, 2rem), calc(100% - clamp(0.8rem, 8vw, 2rem)) 100%, 0 100%, 0 clamp(0.8rem, 8vw, 2rem));


        .mobile-nav-toggle-button {
            margin: 0 clamp(0.4rem, 3vw, 2rem) clamp(0.4rem, 4vw, 1rem);
            width: auto;

            .icon {
                display: block;
                color: var(--clr-mobile-menu-icon);
                font-size: 9vw;
                width: clamp(2rem, 10vw, 4.6rem);
                overflow: hidden;
            }
        }

        .secondary-nav {
            display: none;
        } 
    }

    .user-menu {
        display: none;
    }  

    .secondary-container.wide {
        background-color: var(--clr-bg-secondary);
    
        & > .mobile-nav-toggle-button > .icon {
            color: var(--clr-primary-active);
        }
    }
}

@media (max-width: 40rem) {
    .mobile-menu {
        .mobile-nav {
            margin-inline-start: 0;
            margin-inline-end: 0;
        }
    
        .mobile-user-menu {
            margin-inline-start: 1.5rem;
            margin-inline-end: 1.5rem;
        }

        .footer-menu-item:first-of-type {
            padding-left: 1rem;
        }

        .footer-menu-item {
            font-size: clamp(0.875rem, 3.3vw, 1.2rem);
        }

        .footer-logo img {
            width: 8rem;
        }
    }
}

@media (max-width: 900px) {
    .mobile-menu {
        .footer {
            display: block;
        }

        .footer-logo {
            padding-block-start: 1rem;
            padding-inline-start: 3rem ;
        }
    }
}

@media (max-width: 450px) {
    .mobile-menu {
        .footer nav a {
            display: block;
            padding-block-end: 1.5rem;
            padding-inline-start: 1rem;
        }
    }
}
</style>
