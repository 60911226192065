<template>  
    <div>
        <div v-if="user">
            <router-link @click="$emit('toggleMobileMenu')" to="/internal-area">Dashboard</router-link>
            <button @click="handleLogout">Logout</button>
        </div>
        <div class="sec-menu" v-else>
            <router-link 
                @click="$emit('toggleMobileMenu')"
                :class="[$route.path.includes('/login') ? 'active' : '']" 
                to="/login"
            >
                Login
            </router-link>
            <router-link 
                @click="$emit('toggleMobileMenu')"
                :class="[$route.path.includes('/register') ? 'active' : '']" 
                to="/register"
            >
                Register
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import axios from 'axios';
import { handleError } from '@/assets/js/axios-utility';

export default {
    name: 'TheSecondaryMenu',
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        handleLogout() {
            localStorage.removeItem('token');
            localStorage.removeItem('token_expires');
            this.$store.dispatch('user', null);
            axios.post('/logout')
                .then(() => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
                    this.$emit('toggleMobileMenu');
                    this.$emit('handleLogout');
                    this.$router.push('/');
                })
                .catch(error => {
                    handleError(error);
                });
        },
    },
    emits: ['toggleMobileMenu', 'handleLogout']
}
</script>

<style lang="scss" scoped>
div {
    display: inline;
}

a {
    color: var(--clr-accent);
    cursor: pointer;
    display: inline;
    font-size: 0.9375rem;
    text-transform: uppercase;

    &:hover {
        font-weight: 700;
    }

    &.active {
        color: var(--clr-accent-active);
    }

    &.active:hover {
        cursor: default;
        font-weight: 400;
    }
}

button {
    background: none;
    border: none;
    color: var(--clr-accent);
    cursor: pointer;
    display: inline;
    font-size: 0.9375rem;
    text-transform: uppercase;
    margin-left: 1em;

    &:hover {
        font-weight: 600;
    }

    &:focus {
        outline: none;
    }
}

.sec-menu a + a {
    margin-left: 1rem;
}

@media (max-width: 75rem) {
    a,
    button {
        color: var(--clr-mobile-menu-secondary);
        font-size: 19px;
        font-weight: 700;
        margin: 0;
        padding: 0;
        display: block;
        margin-left: auto;
    }

    div {
        display: block;
    }
}
</style>