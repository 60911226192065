import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/about/about-teem',
        name: 'About TEEM',
        component: () => import('@/views/AboutTeem'),
    },
    {
        path: '/about/project-team',
        name: 'Project Team',
        component: () => import('@/views/ProjectTeam'),
    },
    // BLOG - MAIN
    {
        path: '/blog',
        name: 'Blog',
        component: () => import('@/views/Blog'),
        children: [
            {
                path: '',
                name: 'The Blog Welcome',
                component: () => import('@/components/TheBlogWelcome'),
            },
            {
                path: 'welcome',
                name: 'The Blog Welcome Page',
                component: () => import('@/components/TheBlogWelcome'),
            },
            {
                path: 'article/:id(\\d+)',
                name: 'Blog Article',
                component: () => import('@/components/BlogArticle'),
            },
            {
                path: 'article/:id(\\d+)/preview',
                name: 'Blog Article Preview',
                component: () => import('@/components/BlogArticle'),
            },
        ]
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/Contact'),
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: () => import('@/views/PrivacyPolicy'),
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/auth/Login'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/auth/Register'),
    },
    {
        path: '/registered',
        name: 'Registered',
        component: () => import('@/views/auth/Registered'),
    },
    {
        path: '/verify-user/:verification_code',
        name: 'Veridy User',
        component: () => import('@/views/auth/VerifyUser'),
    },
    {
        path: '/reset-password/:token',
        name: 'Reset Password',
        component: () => import('@/views/auth/ResetPassword'),
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: () => import('@/views/auth/ForgotPassword'),
    },
    {
        path: '/legal-notice',
        name: 'LegalNotice',
        component: () => import('@/views/LegalNotice'),
    },
    {
        path: '/learning-platform/modules',
        name: 'Modules',
        component: () => import('@/views/Modules'),
    },
    {
        path: '/learning-platform/modules/:lmid(\\d+)/:lmsid(\\d+)?/:lmpid(\\d+)?',
        name: 'Module',
        component: () => import('@/views/Module'),
    },
    {
        path: '/learning-platform/modules/:lmid(\\d+)/:lmsid(\\d+)?/:lmpid(\\d+)?/preview',
        name: 'Preview Module',
        component: () => import('@/views/Module'),
    },
    // NEWS - MAIN
    {
        path: '/news',
        name: 'Newest News Article',
        component: () => import('@/components/NewsArticle'),
    },
    {
        path: '/news/article/:id(\\d+)',
        name: 'News Article',
        component: () => import('@/components/NewsArticle'),
    },
    {
        path: '/news/article/:id(\\d+)/preview',
        name: 'News Article Preview',
        component: () => import('@/components/NewsArticle'),
    },

    {
        path: '/policy-paper',
        name: 'PolicyPaper',
        component: () => import('@/views/PolicyPaper'),
    },
    
    // LEARNING PLATFORM - DATABASE - MAIN
    {
        path: '/learning-platform/database',
        name: 'Database',
        component: () => import('@/views/Database'),
    },
    {
        path: '/learning-platform/database/source/:id(\\d+)',
        name: 'Source',
        component: () => import('@/views/Source'),
    },
    {
        path: '/learning-platform/database/source/:id(\\d+)/preview',
        name: 'Source Preview',
        component: () => import('@/views/Source'),
    },

    // LEARNING PLATFORM - INSTRUCTIONS - MAIN
    {
        path: '/learning-platform/instructions',
        redirect: '/learning-platform/instructions/1',
    },
    {
        path: '/learning-platform/instructions/:page(\\d+)',
        name: 'Instructions',
        component: () => import('@/views/Instructions'),
    },
    {
        path: '/learning-platform/instruction/:id(\\d+)',
        name: 'Instruction',
        component: () => import('@/views/Instruction'),
    },
    {
        path: '/learning-platform/instruction/:id(\\d+)/preview',
        name: 'Instruction Preview',
        component: () => import('@/views/Instruction'),
    },

    // LEARNING PLATFORM - THEORIES - MAIN
    {
        path: '/learning-platform/theories',
        redirect: '/learning-platform/theories/1',
    },
    {
        path: '/learning-platform/theories/:page(\\d+)',
        name: 'Theories',
        component: () => import('@/views/Theories'),
    },
    {
        path: '/learning-platform/theory/:id(\\d+)',
        name: 'Theory',
        component: () => import('@/views/Theory'),
    },
    {
        path: '/learning-platform/theory/:id(\\d+)/preview',
        name: 'Theory Preview',
        component: () => import('@/views/Theory'),
    },

    // LEARNING PLATFORM - GOOD PRACTICES - MAIN
    {
        path: '/learning-platform/good-practices',
        redirect: '/learning-platform/good-practices/1',
    },
    {
        path: '/learning-platform/good-practices/:page(\\d+)',
        name: 'Good Practices',
        component: () => import('@/views/GoodPractices'),
    },
    {
        path: '/learning-platform/good-practice/:id(\\d+)',
        name: 'Good Practice',
        component: () => import('@/views/GoodPractice'),
    },
    {
        path: '/learning-platform/good-practice/:id(\\d+)/preview',
        name: 'Good Practice Preview',
        component: () => import('@/views/GoodPractice'),
    },

    // LEARNING PLATFORM - QNAs - MAIN
    {
        path: '/learning-platform/qna',
        redirect: '/learning-platform/qna/1',
    },
    {
        path: '/learning-platform/qna/:page(\\d+)',
        name: 'QnAs',
        component: () => import('@/views/QnAs'),
    },
    {
        path: '/learning-platform/qna/article/:id(\\d+)',
        name: 'QnA',
        component: () => import('@/views/QnA'),
    },
    {
        path: '/learning-platform/qna/article/:id(\\d+)/preview',
        name: 'QnA Preview',
        component: () => import('@/views/QnA'),
    },

    // LEARNING PLATFORM - GLOSSARY - MAIN
    {
        path: '/learning-platform/glossary',
        name: 'Glossary',
        component: () => import('@/views/Glossary'),
    },


    // INTERNAL
    {
        path: '/internal-area',
        name: 'InternalArea',
        component: () => import('@/views/internal/InternalArea'),
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: () => import('@/views/internal/Dashboard'),
            },
            {
                path: 'account',
                name: 'Account',
                component: () => import('@/views/internal/Account'),
            },
            {
                path: 'change-password',
                name: 'Change Password',
                component: () => import('@/views/internal/ChangePassword'),
            },
            // TOOLBOX - INTERNAL
            {
                path: 'toolboxes',
                name: 'Toolboxes',
                component: () => import('@/views/internal/Toolboxes'),
            },
            {
                path: 'toolbox/edit/:id(\\d+)',
                name: 'Edit Toolbox',
                component: () => import('@/components/forms/ToolboxForm'),
            },
            {
                path: 'toolbox/create',
                name: 'Create Toolbox',
                component: () => import('@/components/forms/ToolboxForm'),
            },
            // LEARNING CLASS - INTERNAL
            {
                path: 'learning-classes',
                name: 'Learning Classes',
                component: () => import('@/views/internal/LearningClasses'),
            },
            {
                path: 'learning-class/edit/:id(\\d+)',
                name: 'Learning Class',
                component: () => import('@/views/internal/LearningClass'),
            },
            {
                path: 'learning-class/create',
                name: 'Create Learning Class',
                component: () => import('@/views/internal/LearningClass'),
            },
            // BLOG - INTERNAL
            {
                path: 'blog',
                redirect: '/internal-area/blog/1',
            },
            {
                path: 'blog/:page(\\d+)',
                name: 'Internal Blog',
                component: () => import('@/views/internal/Blog'),
            },
            {
                path: 'blog/edit/:id(\\d+)',
                name: 'Edit Blog Post',
                component: () => import('@/components/forms/PostForm'),
            },
            {
                path: 'blog/create',
                name: 'Create Blog Post',
                component: () => import('@/components/forms/PostForm'),
            },

            // NEWS - INTERNAL
            {
                path: 'news',
                redirect: '/internal-area/news/1',
            },
            {
                path: 'news/:page(\\d+)',
                name: 'Internal News',
                component: () => import('@/views/internal/News'),
            },
            {
                path: 'news/edit/:id(\\d+)',
                name: 'Edit News Post',
                component: () => import('@/components/forms/PostForm'),
            },
            {
                path: 'news/create',
                name: 'Create News Post',
                component: () => import('@/components/forms/PostForm'),
            },

            // LEARNING MODULES - INTERNAL
            {
                path: 'learning-modules',
                name: 'Internal LearningModules',
                component: () => import('@/views/internal/LearningModules'),
                children: [
                    {
                        path: ':lmid(\\d+)',
                        name: 'Internal Learning Module Sections',
                        component: () => import('@/views/internal/LearningModuleSections'),
                        children: [
                            {
                                path: ':lmsid(\\d+)',
                                name: 'Internal Learning Module Pages',
                                component: () => import('@/views/internal/LearningModulePages'),
                                children: [
                                    {
                                        path: ':lmpid(\\d+)',
                                        name: 'Internal Learning Module Page',
                                        component: () => import('@/views/internal/LearningModulePage'),
                                    }
                                ]
                            },
                        ]
                    },
                ]
            },
            {
                path: 'learning-modules/:lmid(\\d+)/:lmsid(\\d+)/:lmpid(\\d+)/:lmcid(\\d+)/edit',
                name: 'Edit Learning Module Component',
                component: () => import('@/components/forms/LearningModuleComponentForm'),
            },
            {
                path: 'learning-modules/:lmid(\\d+)/:lmsid(\\d+)/:lmpid(\\d+)/add',
                name: 'Add Learning Module Component',
                component: () => import('@/components/forms/LearningModuleComponentForm'),
            },


            // DATABASE - INTERNAL
            {
                path: 'database',
                redirect: '/internal-area/database/1',
            },
            {
                path: 'database/:page(\\d+)',
                name: 'Internal Database',
                component: () => import('@/views/internal/Database'),
            },
            {
                path: 'database/edit/:id(\\d+)',
                name: 'Edit Database Entry',
                component: () => import('@/components/forms/SourceForm'),
            },
            {
                path: 'database/create',
                name: 'Create Database Entry',
                component: () => import('@/components/forms/SourceForm'),
            },


            // INSTRUCTIONS - INTERNAL
            {
                path: 'instructions',
                redirect: '/internal-area/instructions/1'
            },
            {
                path: 'instructions/:page(\\d+)',
                name: 'Internal Instructions',
                component: () => import('@/views/internal/Instructions'),
            },
            {
                path: 'instructions/edit/:id(\\d+)',
                name: 'Edit Instructions Entry',
                component: () => import('@/components/forms/PostForm'),
            },
            {
                path: 'instructions/create',
                name: 'Create Instructions Entry',
                component: () => import('@/components/forms/PostForm'),
            },


            // THEORIES - INTERNAL
            {
                path: 'theories',
                redirect: '/internal-area/theories/1'
            },
            {
                path: 'theories/:page(\\d+)',
                name: 'Internal Theories',
                component: () => import('@/views/internal/Theories'),
            },
            {
                path: 'theories/edit/:id(\\d+)',
                name: 'Edit Theories Entry',
                component: () => import('@/components/forms/PostForm'),
            },
            {
                path: 'theories/create',
                name: 'Create Theories Entry',
                component: () => import('@/components/forms/PostForm'),
            },


            // GOOD PRACTICES - INTERNAL
            {
                path: 'good-practices',
                redirect: '/internal-area/good-practices/1'
            },
            {
                path: 'good-practices/:page(\\d+)',
                name: 'Internal Good Practices',
                component: () => import('@/views/internal/GoodPractices'),
            },
            {
                path: 'good-practices/edit/:id(\\d+)',
                name: 'Edit Good Practices Entry',
                component: () => import('@/components/forms/PostForm'),
            },
            {
                path: 'good-practices/create',
                name: 'Create Good Practices Entry',
                component: () => import('@/components/forms/PostForm'),
            },

            // QNA - INTERNAL
            {
                path: 'qna',
                redirect: '/internal-area/qna/1'
            },
            {
                path: 'qna/:page(\\d+)',
                name: 'Internal QnA',
                component: () => import('@/views/internal/QnA'),
            },
            {
                path: 'qna/edit/:id(\\d+)',
                name: 'Edit QnA Entry',
                component: () => import('@/components/forms/PostForm'),
            },
            {
                path: 'qna/create',
                name: 'Create QnA Entry',
                component: () => import('@/components/forms/PostForm'),
            },

            // Glossary
            {
                path: 'glossary',
                name: 'Internal Glossary',
                component: () => import('@/views/internal/Glossary'),
            },

            // Uploads
            {
                path: 'uploads',
                name: 'Internal Uploads',
                component: () => import('@/views/internal/Uploads'),
            },

            // Key Words
            {
                path: 'key-words',
                name: 'Internal Key Words',
                component: () => import('@/views/internal/KeyWords'),
            },

            // Post Categores
            {
                path: 'post-categories',
                name: 'Internal Post Categories',
                component: () => import('@/views/internal/PostCategories'),
            },


            {
                path: 'users',
                name: 'Users',
                component: () => import('@/views/internal/Users'),
            },
            {
                path: 'website-configuration',
                name: 'Website Configuration',
                component: () => import('@/views/internal/WebsiteConfiguration'),
            },
            
        ]
    },

    // Errors
    {
        path: '/404',
        name: 'NotFound',
        component: () => import('@/views/errors/NotFound'),
    },
    {
        path: '/401',
        name: 'Unauthorized',
        component: () => import('@/views/errors/Unauthorized'),
    },
    {
        path: '/500',
        name: 'ServerError',
        component: () => import('@/views/errors/ServerError'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;