<template>
    <footer class="container flex">
        <div class="primary-container flex">
            <nav>
                <router-link 
                    :class="[$route.path.includes('/legal-notice') ? 'active' : '', 'footer-menu-item']" 
                    to="/legal-notice"
                >Legal Notice</router-link>
                <router-link 
                    :class="[$route.path.includes('/contact') ? 'active' : '', 'footer-menu-item']" 
                    to="/contact"
                >Contact</router-link>
                <router-link 
                    :class="[$route.path.includes('/privacy-policy') ? 'active' : '', 'footer-menu-item']" 
                    to="/privacy-policy"
                >Privacy Policy</router-link>
            </nav>
            <div class="footer-logo">
                <img :src="`${publicPath}images/eu-erasmus-logo.png`" alt="">
            </div>
        </div>
        <div class="secondary-container" />
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            publicPath: process.env.BASE_URL,
        }
    }
}
</script>

<style lang="scss" scoped>
footer {
    position: relative;
    bottom: 0;
    padding-bottom: 4rem;
    padding-top: 8rem;
}

nav > a {
    margin-right: clamp(1.5rem, 1rem + 2vw, 4rem);
}

.container {
    margin: 0 auto;
    justify-content: space-between;
}

.flex {
    align-items: flex-end;
    flex-wrap: nowrap;
}

.footer-logo img {
    width: 300px;
}

.footer-menu-item {
    color: var(--clr-primary);
    border-bottom: 0.09em solid;
    font-size: clamp(1rem, 1rem + 0.2vw, 1.5625rem);
    font-weight: 700;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    text-decoration: none;

    &.active {
        color: var(--clr-primary-active);
        cursor: default;
    }

    &:hover {
        border-bottom-width: 0.13em;
    }
}

.footer-navbar {
    position: relative;
    width: 100%;
}

.primary-container {
    padding-left: 2rem;
    justify-content: flex-start;
}

@media (max-width: 75rem) {
    footer {
        padding-top: 4rem;
        padding-bottom: 2rem;
    }

    .footer-logo img {
        width: 250px;
    }

    .primary-container {
        justify-content: flex-end;

        nav {
            display: none;
        }
    }

    .secondary-container {
        display: none;
    }
}
</style>