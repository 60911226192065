<template>
    <transition name="fade">
        <div v-if="modal" class="modal-overlay" />
    </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['modal']),
    },
}
</script>

<style lang="scss" scoped>
.modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #00000090;

    z-index: 100;

    overscroll-behavior: contain;
}
</style>