<template>
    <transition name="fade">
        <div class="loading-overlay" v-show="loading">
            <q-spinner-oval
                class="loading-symbol"
                color="accent"
                size="2rem"
            />
        </div> 
    </transition>  
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['loading']),
    },
}
</script>

<style lang="scss" scoped>
.loading-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff90;
    z-index: 102;
}

.loading-symbol {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
}
</style>