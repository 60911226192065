import axios from 'axios';

// DEV
// axios.defaults.baseURL = 'http://localhost:8000/';

// PROD
axios.defaults.baseURL = 'https://teem.geschichtsdidaktik.eu/api/';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';