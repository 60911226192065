<template>
  <q-banner v-if="!on_line" nline-actions class="text-white bg-red" v-html="'Lost Connection to the Internet'" />
  <div id="news-background" />
  <TheHeader @handleLogout="handleLogout" />
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  <TheFooter/>
  <TheLoadingIndicator />
  <TheModalOverlay />
</template>

<script>
import axios from 'axios';
import { useQuasar } from 'quasar';
import { mapGetters } from 'vuex';

import { promiseGetRequest } from './assets/js/axios-utility';

import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
import TheLoadingIndicator from './components/TheLoadingIndicator.vue';
import TheModalOverlay from './components/TheModalOverlay.vue';

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
    TheLoadingIndicator,
    TheModalOverlay
  },
  data() {
    return {
      refresh_token_timer: null,
      publicPath: process.env.BASE_URL,

      on_line: navigator.onLine,
    }
  },
  computed: {
    ...mapGetters(['user', 'loading']),
  },
  setup() {
      const quasar = useQuasar();

      return {
          quasar
      }
  },
  created() {
    this.loadUserData();
    this.setTimer();
    this.configureWebsite();
  },
  mounted() {
      window.addEventListener('online', this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeUnmount() {
      window.removeEventListener('online', this.updateOnlineStatus);
      window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    // Requests
    getMeRequest() {
      return promiseGetRequest('me');
    },
    refreshTokenRequest() {
      return promiseGetRequest('refresh-token');
    },
    getWebsiteConfigurationRequest() {
      return promiseGetRequest('website-configuration');
    },

    // Actions
    async configureWebsite() {
      const response = await this.getWebsiteConfigurationRequest();
      let website_configuration = {};
      for (let configuration of response) {
        website_configuration[configuration.name] = configuration.value; 
      }
      this.$store.dispatch('website_configuration', website_configuration);
      this.$store.dispatch('loading', false);
    },
    async loadUserData() {
      if (localStorage.getItem('token')) {
        if (localStorage.getItem('token_expires') > new Date().getTime()) {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
          const response = await this.getMeRequest();
          this.$store.dispatch('loading', false);
          this.$store.dispatch('user', response);
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('token_expires');
          clearInterval(this.refresh_token_timer);
          this.refresh_token_timer = null;
        }
      }
    },
    async refreshToken() {
      const response = await this.refreshTokenRequest().catch(() => {
        clearInterval(this.refresh_token_timer);
        this.refresh_token_timer = null;
      });

      if (response) {
        localStorage.setItem('token', response.access_token);
        const expire_time = new Date().getTime() + (response.expires_in * 1000);
        localStorage.setItem('token_expires', expire_time);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
      }
    },
    handleLogout() {
      clearInterval(this.refresh_token_timer);
      this.refresh_token_timer = null;
    },

    // Functions
    setTimer() {
      if (localStorage.getItem('token')) {
        if (localStorage.getItem('token_expires') > new Date().getTime()) {
          this.refresh_token_timer = setInterval(this.refreshToken, 14 * 60 * 1000);
        }
      }
    },

    // online checker
    updateOnlineStatus(e) {
      const { type } = e;
      this.on_line = type === 'online';
    },

    // language checker
    updateLanguageSettings() {
      if (localStorage.getItem('lang')) {
        this.$store.dispatch('lang', localStorage.getItem('lang'));
      } else {
        this.$store.dispatch('lang', 'en');
      }
    }
  },
  watch: {
    user: function(val) {
      if (val) {
        this.setTimer();
      }
    },
    on_line: function(val) {
      if (val) {
        this.$store.dispatch('loading', false);
        this.quasar.notify({
          position: 'bottom-left', 
          message: 'Connection to Internet reestablished.',
          color: 'positive'
        });
      } else {
        this.$store.dispatch('loading', true);
        this.quasar.notify({
          position: 'bottom-left', 
          message: 'Lost Connection to the Internet.',
          color: 'negative'
        });
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  background-color: var(--clr-bg-primary);
}
</style>
