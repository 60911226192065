import { createStore } from 'vuex'

export default createStore({
    state: {
        layout: 1,
        loading: false,
        menu: 1,
        modal: false,
        user: null,
        website_configuration: null, 
    },
    getters: {
        layout: state => state.layout,
        loading: state => state.loading,
        menu: state => state.menu,
        modal: state => state.modal,
        user: state => state.user,
        website_configuration: state => state.website_configuration,
    },
    actions: {
        layout(context, layout) {
            context.commit('layout', layout);
        },
        loading(context, loading) {
            context.commit('loading', loading);
        },
        menu(context, menu) {
            context.commit('menu', menu);
        },
        modal(context, modal) {
            context.commit('modal', modal);
        },
        user(context, user) {
            context.commit('user', user);
        },
        website_configuration(context, website_configuration) {
            context.commit('website_configuration', website_configuration);
        }
    },
    mutations: {
        layout(state, layout) {
            state.layout = layout;
        },
        loading(state, loading) {
            state.loading = loading;
        },
        menu(state, menu) {
            state.menu = menu;
        },
        modal(state, modal) {
            state.modal = modal;
        },
        user(state, user) {
            state.user = user;
        },
        website_configuration(state, website_configuration) {
            state.website_configuration = website_configuration;
        }
    },
});

// layout 1 -> with secondary container
// layout 2 -> without secondary container
// layout 3 -> with big secondary container
// menu 1 -> normal header
// menu 2 -> blog header
// menu 3 -> news header